// Here you can add other styles
.c-sidebar-brand{
    background: #ebedef !important; 
  }
  
  .c-login-card{
    background: $h-blue !important; 
  }

  .c-color-card-blue{
    background-color: $h-blue !important; 
  }

  .c-color-card-orange{
    background-color: $h-orange !important; 
  }

  //clases custom para steps dark
  //active steps
  .ui.steps .active.step.dk {
    background: #f3f4f573 !important;
  }
  .ui.steps .active.step.dk .title{
    color: #fff !important;
  }
  .ui.steps .active.step.dk .description{
    color: #fff !important;
  }
  .ui.steps .active.step.dk .icon{
    color: #fff  !important;
  }
  .ui.steps .step.active.dk:after{
    background: #cccbcb3a;
  }
  //disabled steps
  .ui.steps .disabled.step.ddk  {
    background: #cccbcb3a !important;
  }
  .ui.steps .disabled.step.ddk .title{
    color: rgb(192, 192, 192) !important;
  }
  .ui.steps .disabled.step.ddk .icon{
    color: rgb(192, 192, 192) !important;
  }
  .ui.steps .step.disabled.ddk:after{
    background: #cccbcb3a;
  }
  .ui.steps .step.disabled.ddk .description{
    color: #fff !important;
  }
  //steps 
  .ui.steps .step.sdk  {
    background: #ebedef21 !important;
  }
  .ui.steps .step.sdk .title{
    color: #fff !important;
  }
  .ui.steps .step.sdk .description{
    color: #fff !important;
  }
  .ui.steps .step.sdk .icon{
    color: rgb(192, 192, 192) !important;
  }
  .ui.steps .step.sdk:after{
    background: #ebedef21;
  }
  //clases custom para steps tema default
  //active step
  .ui.steps .active.step.df  {
      background: #c9c9c98c !important;
  }
  .ui.steps .step.active.df:after{
    background: #c9c9c98c;
  }
  .ui.steps .active.step.df .title{
    color: rgb(27, 27, 27) !important;
  }
  //step
  .ui.steps .step.sdf:after{
    background: #fff;
  }
  .ui.steps .step.sdf .title{
    color: rgb(27, 27, 27) !important;
  }
  .ui.steps .step.sdf:after{
    background: #fff;
  }
  .ui.steps .step.sdf  {
    background: #ebedef21 !important;
  }
  //disabled step
  .ui.steps .disabled.step.ddf  {
    background: #dededf21 !important;
  }
  .ui.steps .active.step.ddf .title{
    color: rgb(27, 27, 27) !important;
  }
  // .ui.steps .active.step.sdf .title{
    //   color: #fff !important;
    // }
  // .ui.steps .active.step.df .title{
  //   color: #fff !important;
  // }

  .google-btn {
    cursor: pointer;
      margin-top: 5px;
      width: 100%;
      height: 42px;
      background-color: #4285f4;
      border-radius: 2px;
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  
      transition: box-shadow .3s ease;
  
      .google-icon-wrapper {
          position: absolute;
          margin-top: 1px;
          margin-left: 1px;
          width: 40px;
          height: 40px;
          border-radius: 2px;
          background-color: #fff;
      }
      .google-icon {
          position: absolute;
          margin-top: 11px;
          margin-left: 11px;
          width: 18px;
          height: 18px;
      }
      .btn-text {
          float: left;
          margin: 10px 80px;
          color: #fff;
          font-size: 14px;
          letter-spacing: 0.2px;
      }
      &:hover {
          box-shadow: 0 0 6px #4285f4;
      }
      &:active {
          background: #1669f2;
      }
    }

.overflow-x-scroll{
  overflow-x: scroll;
}

.overflow-y-scroll{
  overflow-y: scroll;
}

.ql-editor{
  min-height:100px;
}